import React, { forwardRef } from 'react'

import DatePicker from 'react-datepicker'

const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <input readOnly onClick={onClick} ref={ref} value={value} className="form-control" />
));

export default function CustomDatePicker(props) {
    return (
        <DatePicker className="form-control" showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" {...props} customInput={<DatePickerInput />} />
    )
}