export default {
    downloadFile: function(url, key) {
        var xhr = new XMLHttpRequest(); 
        xhr.open("GET", url); 
        xhr.responseType = "blob";
        xhr.onload = function() {
            if (xhr.status && xhr.status === 200) {
                saveFile(xhr.response);
            } 
        }
        xhr.send();

        function saveFile(blob) {
            var fileReader = new FileReader();

            fileReader.onload = function (evt) {
                var result = evt.target.result;
        
                localStorage.setItem(key, result);
            };
        
            fileReader.readAsDataURL(blob);
        }
    }
}