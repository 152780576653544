import React from 'react'

import { Route } from 'react-router-dom'

import TodoList from 'Areas/Todo/List'
import TodoCreate from 'Areas/Todo/Create'

export default function() {
    return (
        <div className="container">
            <Route path="/todo/create" component={TodoCreate} />
            <Route path="/todo" component={TodoList} exact />
        </div>
    )
}