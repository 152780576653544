import { store } from 'app/store'

import http from 'Services/Http'

export default {
    getSongMetadata: async function(id) {
        const state = store.getState()

        const existingSong = state.music.songCache[id]
        if (existingSong) {
            return existingSong
        }

        const response = await http.get(`/music/songs/${id}`)

        return response.data
    }
}