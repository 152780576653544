import React, { useState } from 'react'

import NavTitle from 'components/NavTitle'
import { Card, Form, Button } from 'react-bootstrap'

import http from 'Services/Http'

import debounce from 'Utils/debounce'

export default function Home() {
    const [brightness, setBrightness] = useState(100)
    const [lightType, setLightType] = useState('C0')

    const onBrightnessChanged = (e) => {
        setBrightness(e.target.value)
    }

    const onTypeChanged = (e) => {
        setLightType(e.target.value)
    }

    const turnOnLight = (e) => {
        triggerLightChange(lightType, brightness)
    }

    const turnOffLights = async (e) => {
        await http.post(`h1/lights/off`)
    }

    const triggerLightChange = async (type, brightness) => {
        await http.post(`h1/lights/change`, {
            type: type,
            brightness: brightness
        })
    }

    return (
        <div className="d-flex flex-column flex-fill">
            <NavTitle text="Home" />
            <Card className="d-flex">
                <Card.Body>
                    <Card.Title>Lights</Card.Title>
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select onChange={onTypeChanged} defaultValue={lightType}>
                            <option value="">Select a light type...</option>
                            <option value="C0">Last Mode Used</option>
                            <option value="S11">Warm white</option>
                            <option value="S12">Daylight</option>
                            <option value="S13">Cool white</option>
                            <option value="S14">Night light</option>
                            <option value="S6">Cozy</option>
                            <option value="S17">True colors</option>
                            <option value="S16">Relax</option>
                            <option value="S19">Plant growth</option>
                            <option value="S15">Focus</option>
                            <option value="S18">TV time</option>
                            <option value="S29">Candlelight</option>
                            <option value="S5">Fireplace</option>
                            <option value="S22">Fall</option>
                            <option value="S26">Club</option>
                            <option value="S3">Sunset</option>
                            <option value="S2">Romance</option>
                            <option value="S4">Party</option>
                            <option value="S8">Pastel colors</option>
                            <option value="S20">Spring</option>
                            <option value="S21">Summer</option>
                            <option value="S7">Forest</option>
                            <option value="S24">Jungle</option>
                            <option value="S25">Mojito</option>
                            <option value="S1">Ocean</option>
                            <option value="S23">Deep dive</option>
                            <option value="S31">Pulse</option>
                            <option value="S30">Golden white</option>
                            <option value="S32">Steampunk</option>
                            <option value="S9">Wake-up</option>
                            <option value="S10">Bedtime</option>
                            <option value="S27">Christmas</option>
                            <option value="S28">Halloween</option>
                            <option value="C2">Blue</option>
                            <option value="C3">Green</option>
                            <option value="C6">Lime</option>
                            <option value="C7">Orange</option>
                            <option value="C8">Pink</option>
                            <option value="C9">Purple</option>
                            <option value="C1">Red</option>
                            <option value="C5">Turquoise</option>
                            <option value="C10">White</option>
                            <option value="C4">Yellow</option>
                            <option value="R777">Random (same mode for all the lights)</option>
                            <option value="R7777">Random (different mode for each light)</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Brightness</Form.Label>
                        <Form.Range onChange={onBrightnessChanged} defaultValue={brightness} min="10" max="100" />
                    </Form.Group>
                    <div>
                        <Button variant="primary" onClick={turnOnLight}>
                            Turn On
                        </Button>
                        <Button variant="primary" onClick={turnOffLights} className="mx-2">
                            Turn Off
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}