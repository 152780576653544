import React from 'react'

import { useSelector } from 'react-redux'

import NavTitle from 'components/NavTitle'

import TrackList from 'Areas/Music/TrackList'
import TrackListItem from 'Areas/Music/TrackListItem'

export default function Queue() {
    const queue = useSelector(state => state.music.queue)

    return (
        <div>
            <NavTitle text="Queue" />
            <TrackList>
                {queue.items.map((x, index) => (
                    <TrackListItem key={x.id} position={index + 1} song={x} active={index === 0} />
                ))}
            </TrackList>
        </div>
    )
}