import React from 'react'

import NavTitle from 'components/NavTitle'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export default function Home() {
    return (
        <div className="d-flex flex-column flex-fill">
            <NavTitle text="Health Passport" />
            <div className="d-grid gap-2">
                <Button as={Link} to="/flashpoint/passport" variant="primary" size="lg">
                    <FontAwesomeIcon icon="qrcode" />
                </Button>
                <Button as={Link} to="/flashpoint/prankport" variant="primary" size="lg">
                    <FontAwesomeIcon icon="rocket" />
                </Button>
                <Button as={Link} to="/flashpoint/green" variant="primary" size="lg">
                    <FontAwesomeIcon icon="check" />
                </Button>
            </div>
        </div>
    )
}