import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { Navbar, Container, Nav, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import NavSidebar from './NavSidebar'

export default function() {
    const title = useSelector(state => state.core.title)

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const onToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }
    
    const onHideSidebar = () => {
        setIsSidebarOpen(false)
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container fluid>
                <Navbar.Toggle className="d-block" onClick={onToggleSidebar} />
                <Nav className="ms-2 me-auto">
                    <Navbar.Brand>{title}</Navbar.Brand>
                </Nav>
                <NavSidebar isOpen={isSidebarOpen} onHide={onHideSidebar} />
            </Container>
        </Navbar>
    )
}