import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useParams } from 'react-router-dom'

import { Modal, Button, Form } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useForm } from 'react-hook-form'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'
import TrackList from './TrackList'
import PlaylistTrackListItem from './PlaylistTrackListItem'

import EditPlaylistModal from 'Areas/Music/EditPlaylistModal'

import { setQueue } from './MusicSlice'
import MusicPlayer from './Services/MusicPlayer'

export default function ViewPlaylist() {
    const dispatch = useDispatch()

    const [playlist, setPlaylist] = useState()
    const [isEditing, setIsEditing] = useState()

    const { id } = useParams();

    const loadPlaylist = async () => {
        const response = await http.get(`/music/playlists/${id}`)

        setPlaylist(response.data)
    }

    const onPlayPlaylist = () => {
        let queue = []

        for (let item of playlist.items) {
            queue.push(item.song)
        }

        dispatch(setQueue(queue))

        MusicPlayer.playQueue()
    }

    const onPlay = (index) => {
        let queue = []

        for (let i = index; i < playlist.items.length; i++) {
            const item = playlist.items[i]

            queue.push(item.song)
        }

        dispatch(setQueue(queue))

        MusicPlayer.playQueue()
    }

    useEffect(async () => {
        loadPlaylist()
    }, [id])

    if (!playlist) {
        return (
            <></>
        )
    }

    return (
        <div>
            <EditPlaylistModal playlist={playlist} show={isEditing} onHide={() => setIsEditing(false)} />

            <div className="d-flex d-row align-items-center justify-content-between my-4">
                <NavTitle text={playlist.name} />
                <div className="d-flex flex-row">
                    <Button variant="outline-secondary" size="lg" onClick={onPlayPlaylist}>
                        <FontAwesomeIcon icon="play-circle" size="lg" className="text-primary" />
                    </Button>
                    <Button variant="primary" className="ms-2" onClick={() => setIsEditing(true)}>
                        <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                </div>
                <div>
                    {playlist.items.length} songs
                </div>
            </div>
            <TrackList>
                {playlist.items.map((x, index) => (
                    <PlaylistTrackListItem key={x.id} playlist={playlist} playlistItem={x} position={index + 1} onPlay={() => onPlay(index)} />
                ))}
            </TrackList>
        </div>
    )
}