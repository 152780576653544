import React, { useContext } from 'react'

import { viewportContext } from 'components/ViewportProvider';

export default function useViewport() {
    /* We can use the "useContext" Hook to acccess a context from within
       another Hook, remember, Hooks are composable! */
    const { width, height } = useContext(viewportContext);

    return { width, height };
}