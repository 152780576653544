import React from 'react'

import NavTitle from 'components/NavTitle'

import QRCode from './SampleQRCode.png'

export default function Green() {
    return (
        <div className="d-flex flex-fill">
            <NavTitle text="Not a Passport" />
            <div className="d-flex align-items-center flex-fill overflow-auto">
                <div className="d-flex flex-fill justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <div style={{ 'maxWidth': '75%' }} className="text-center">
                            <p className="my-4">I don't wish to disclose my health status, but I wish to support your business.</p>
                            <p className="my-4">If that works for you, simply say thank you, and let's do business.</p>
                            <p className="my-4">If not, politely as us to leave.</p>
                        </div>
                        <img style={{ 'maxWidth': '60%', height: 'auto' }} src={QRCode} />
                    </div>
                </div>
            </div>
        </div>
    )
}