import React from 'react'


import { useSelector } from 'react-redux'

import { Offcanvas } from 'react-bootstrap'

import auth from 'Services/Authorization'

import AuthenticatedNavSidebar from './AuthenticatedNavSidebar'
import AnonymousNavSidebar from './AnonymousNavSidebar'

export default function NavSidebar({ isOpen, onHide }) {
    const token = useSelector(state => state.authorization.token)

    const isAuthenticated = auth.isAuthenticated(token)

    return (
        <Offcanvas className="z-top" show={isOpen} scroll={true} onHide={onHide}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Navigation</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {isAuthenticated && <AuthenticatedNavSidebar />}
                {!isAuthenticated && <AnonymousNavSidebar />}
            </Offcanvas.Body>
        </Offcanvas>
    )
}