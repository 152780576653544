import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ListGroup, Card } from 'react-bootstrap'

import { Link } from 'react-router-dom'

import PlaylistItem from './PlaylistItem'
import NowPlaying from './NowPlaying'

import './Sidebar.css'

import http from 'Services/Http'

export default function() {
    const playlists = useSelector(state => state.music.playlists)

    const createPlaylist = async () => {
        const response = await http.post(`/music/playlists`)
    }

    return (
        <div className="d-flex">
            <Card className="sidebar">
                <div className="text-center">
                    <ListGroup variant="flush">
                        <ListGroup.Item action as={Link} to='/music'>Library</ListGroup.Item>
                        <ListGroup.Item action as={Link} to='/music/discover'>Discover</ListGroup.Item>
                        <ListGroup.Item action as={Link} to='/music/downloads'>Downloads</ListGroup.Item>
                        <ListGroup.Item action as={Link} to='/music/upload'>Upload</ListGroup.Item>
                    </ListGroup>
                    <hr />
                    <ListGroup variant="flush">
                        <ListGroup.Item action onClick={createPlaylist}>Create Playlist</ListGroup.Item>
                    </ListGroup>
                    <hr />
                    <ListGroup variant="flush" className="playlist-container">
                        {playlists.map(x => (
                            <PlaylistItem playlist={x} key={x.id}>{x.name}</PlaylistItem>
                        ))}
                    </ListGroup>
                    <Card.Body className="position-absolute bottom-0 w-100">
                        <NowPlaying />
                    </Card.Body>
                </div>
            </Card>
        </div>
    );
}