import React from 'react'

import Title from 'components/Title'
import TrackList from './TrackList'

import { useSelector } from 'react-redux'
import TrackListItem from './TrackListItem'

export default function Queue() {
    const queue = useSelector(state => state.music.queue)

    return (
        <div>
            <Title>Queue</Title>
            <TrackList>
                {queue.items.map((x, index) => (
                    <TrackListItem key={x.id} position={index + 1} song={x} active={index === 0} />
                ))}
            </TrackList>
        </div>
    )
}