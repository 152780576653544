import React from 'react'
import { useSelector } from 'react-redux'

import Title from 'components/Title'

export default function NowPlayingOptions() {
    const playingSongMetadata = useSelector(state => state.music.playingSongMetadata)

    return (
        <div className="d-flex flex-column flex-fill m-2">
            <Title>Options</Title>
            <div className="d-flex flex-column align-items-center mx-2 my-2">
                <h5>{playingSongMetadata.song.name}</h5>
                <h6 className="text-muted">{playingSongMetadata.song.artist}</h6>
            </div>


        </div>
    )
}