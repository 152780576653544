import React from 'react'

import { useForm } from 'react-hook-form'

import { Modal, Form, Button } from 'react-bootstrap'

import http from 'Services/Http'

export default function EditPlaylistModal({ playlist, show, onHide }) {
    const { register, handleSubmit } = useForm();

    const saveEdits = async (data) => {
        playlist.name = data.name

        const response = await http.patch(`/music/playlists/${playlist.id}`, {
            name: data.name
        })

        onHide()
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Playlist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control defaultValue={playlist.name} type="text" {...register('name')} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Close
            </Button>
            <Button variant="primary" onClick={handleSubmit(saveEdits)}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>
    )
}