import React from 'react'

import './Host.css'

import { Switch, Route, Link } from 'react-router-dom'

import Home from './Home'
import Playlists from './Playlists'
import Library from './Library'
import Artists from './Artists'
import ViewArtist from './ViewArtist'
import ViewAlbum from './ViewAlbum'
import Discover from './Discover'
import Queue from './Queue'
import ViewPlaylist from './ViewPlaylist'
import NowPlayingCard from './NowPlayingCard'
import { useSelector } from 'react-redux'

export default function Host() {
    const playingSongId = useSelector(state => state.music.playingSongId)
   // 'maxHeight': '30rem', 
    return (
        <div className="d-flex flex-column flex-fill overflow-auto">
            <div className="d-flex flex-grow-1 px-3 overflow-auto">
                <div className="d-flex flex-column flex-fill">
                    <Switch>
                        <Route path={`/music/playlist/:id`} component={ViewPlaylist} />
                        <Route path={`/music/discover`} component={Discover} />
                        <Route path={`/music/playlists`} component={Playlists} />
                        <Route path={`/music/queue`} component={Queue} />
                        <Route path={`/music/library`} component={Library} />
                        <Route path={`/music/artists/:artist/:album`} component={ViewAlbum} />
                        <Route path={`/music/artists/:id`} component={ViewArtist} />
                        <Route path={`/music/artists`} component={Artists} />
                        <Route path={`/music`} component={Home} />
                    </Switch>
                    {playingSongId && (<div className="now-playing-card-height my-2" />)}
                </div>
            </div>
            <div className="now-playing-card my-2 px-2 w-100 z-top">
                <NowPlayingCard />
            </div>
            <div className="d-flex">
                <div className="d-flex flex-row flex-grow-1 nav-host">
                    <div className="nav-button flex-fill d-flex justify-content-center align-items-center">
                        <Link to="/music" className="text-decoration-none text-light">Home</Link>
                    </div>
                    <div className="nav-button flex-fill d-flex justify-content-center align-items-center">
                        <Link to="/music/discover" className="text-decoration-none text-light">Discover</Link>
                    </div>
                    <div className="nav-button flex-fill d-flex justify-content-center align-items-center text-primary">
                        <Link to="/music/playlists" className="text-decoration-none text-light">Playlists</Link>
                    </div>
                    <div className="nav-button flex-fill d-flex justify-content-center align-items-center">
                        <Link to="/music/artists" className="text-decoration-none text-light">Library</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}