import { combineReducers } from 'redux'

import CoreSlice from 'Areas/Core/CoreSlice'
import AuthorizationSlice from 'Areas/Authorization/AuthorizationSlice'
import MusicSlice from 'Areas/Music/MusicSlice'
import MusicSettingsSlice from 'Areas/Music/MusicSettingsSlice'
import MusicLibrarySlice from 'Areas/Music/LibrarySlice'

export default combineReducers({
    authorization: AuthorizationSlice,
    music: MusicSlice,
    musicSettings: MusicSettingsSlice,
    musicLibrary: MusicLibrarySlice,
    core: CoreSlice
})