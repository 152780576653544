import React, { useEffect, useCallback, useState } from "react";
import ReactDOM from 'react-dom';

import useViewport from "./useViewport";

const defaultContextMenu = (anchorPoint) => {
    return (
        <></>
    )
}

const ContextMenuComponent = (anchorPoint, component, handleClick) => {
    const bodyElement = document.body
    const el = document.createElement('div')
    el.id = 'active-context-menu'

    const onMenuClick = (e) => {
      e.stopPropagation()

      handleClick()
    }

    const element = (
        <div className="menu shadow" style={{ top: anchorPoint.y, left: anchorPoint.x }} onClick={onMenuClick}>
            {component}
        </div>
    )
 
    return ReactDOM.createPortal(
	    element,
	    bodyElement.appendChild(el)
    )
}

const useContextMenu = (component) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [contextMenu, setContextMenu] = useState(defaultContextMenu(anchorPoint))

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  const { width } = useViewport()

  useEffect(() => {
    let position = anchorPoint
    if (position.x + 250 > width) {
      position = {
        x: position.x - 250,
        y: position.y
      }
    }

    if (show) {
        setContextMenu(ContextMenuComponent(position, component, handleClick))
    } else {
        setContextMenu(defaultContextMenu(position))
    }
  }, [show, anchorPoint])

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener('contextmenu', handleClick)
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleClick);
    };
  });

  const bindContextMenu = (e) => {
      e.preventDefault()
      setAnchorPoint({ x: e.pageX, y: e.pageY });

      setShow(true)
  }

  return { bindContextMenu, contextMenu };
};

export default useContextMenu;