import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'

import Downloader from 'Services/Downloader'

import { addToQueue, addToDownloadQueue } from './MusicSlice'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import http from 'Services/Http'

export default function TrackContextMenu({ song, additionalItems }) {
    const dispatch = useDispatch()
    const playlists = useSelector(state => state.music.playlists)

    const [isSelectingPlaylist, setIsSelectingPlaylist] = useState(false)

    const onAddToQueue = () => {
        dispatch(addToQueue(song))
    }

    const onStartAddingToPlaylist = (e) => {
        e.stopPropagation()

        setIsSelectingPlaylist(true)
    }
    
    const onStopAddingToPlaylist = (e) => {
        e.stopPropagation()

        setIsSelectingPlaylist(false)
    }

    const onAddToSelectedPlaylist = async (playlist) => {
        const response = await http.post(`/music/playlists/songs`, {
            playlistId: playlist.id,
            songId: song.id
        })
    }

    const onDownloadSong = async (e) => {
        e.stopPropagation()

        dispatch(addToDownloadQueue(song))

        const songStreamResponse = await http.get(`/music/songs/${song.id}/stream`)
        const songUrl = songStreamResponse.data.url

        Downloader.downloadFile(songUrl, `Music/${song.id}`)
    }

    if (isSelectingPlaylist) {
        return (
            <ListGroup>
                <ListGroup.Item action onClick={onStopAddingToPlaylist}>
                    <FontAwesomeIcon icon="arrow-left" />{' '}Back
                </ListGroup.Item>
                {playlists.map(x => (
                    <ListGroup.Item action onClick={() => onAddToSelectedPlaylist(x)}>{x.name}</ListGroup.Item>
                ))}
            </ListGroup>
        )
    }

    return (
        <ListGroup>
            {additionalItems}
            <ListGroup.Item action onClick={onAddToQueue}>Add to queue</ListGroup.Item>
            <ListGroup.Item action onClick={onStartAddingToPlaylist}>Add to playlist</ListGroup.Item>
            <ListGroup.Item action onClick={onDownloadSong}>Download</ListGroup.Item>
        </ListGroup>
    )
}