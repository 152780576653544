import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Card } from 'react-bootstrap'

import PlayButton from 'Areas/Music/PlayButton'

import NowPlaying from './NowPlaying'
import useViewStack from 'hooks/useViewStack'

export default function NowPlayingCard() {
    const dispatch = useDispatch()
    const viewStack = useViewStack()

    const playingSongMetadata = useSelector(state => state.music.playingSongMetadata)

    const onViewNowPlaying = () => {
        viewStack.current.pushView(<NowPlaying />)
    }

    if (!playingSongMetadata) {
        return <></>
    }

    return (
        <Card className="rounded-3 now-playing-card-height" bg="secondary" onClick={onViewNowPlaying}>
            <Card.Body style={{ 'padding': '0.5rem' }} className="d-flex align-items-center" onClick={onViewNowPlaying}>
                <div className="d-flex flex-row flex-fill justify-content-between overflow-hidden">
                    <div className="d-flex flex-column overflow-hidden">
                        <span className="text-truncate">{playingSongMetadata.song.name}</span>
                        <span className="text-muted small text-truncate" style={{ fontSize: '0.75rem'}}>{playingSongMetadata.song.artist}</span>
                    </div>
                    <div>
                        <PlayButton song={playingSongMetadata.song} />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}