import React, { useState, useEffect } from 'react'

import useTimeout from 'hooks/useTimeout'

import Stage1 from './PrankPassport/Stage1'
import Stage2 from './PrankPassport/Stage2'
import Stage3 from './PrankPassport/Stage3'

export default function PrankPassport() {
    const [stageNumber, setStageNumber] = useState(0)

    useTimeout(() => {
        setStageNumber(1)
    }, 15000)

    useEffect(() => {
        if (stageNumber != 2) {
            return
        }

        const timer = setTimeout(() => {
            setStageNumber(3)
        }, 60000);
        return () => clearTimeout(timer);
    }, [stageNumber]);

    switch (stageNumber) {
        case 0:
            return <Stage1 />
        case 1:
            return <Stage2 continuation={() => setStageNumber(2)} />
        case 2:
            return <Stage1 />
        case 3:
            return <Stage3 />
    }
}