import React, { useState } from 'react'

import { ListGroup, Form } from 'react-bootstrap'

import useContextMenu from 'hooks/useContextMenu'

import http from 'Services/Http'

export default function({ item }) {
    const [isDeleted, setIsDeleted] = useState(false)

    const onItemCompleteChanged = async (target, item) => {
        item.isComplete = target.checked

        const response = await http.patch(`todo/${item.id}/state`, {
            isComplete: target.checked
        })
    }

    const onDeleteItem = async () => {
        const response = await http.delete(`todo/${item.id}`)

        setIsDeleted(true)
    }

    const contextMenuComponent = () => {
        return (
            <ListGroup>
                <ListGroup.Item action onClick={onDeleteItem}>Delete</ListGroup.Item>
            </ListGroup>
        )
    }

    const { bindContextMenu, contextMenu } = useContextMenu(contextMenuComponent())

    if (isDeleted) {
        return <></>
    }

    return (
        <ListGroup.Item onContextMenu={bindContextMenu}>
            {contextMenu}
            <div className="d-flex justify-content-between">
                <div>
                    <Form.Check className="form-control-lg" id={`${item.id}-complete`} label={item.title} defaultChecked={item.isComplete} onChange={e => onItemCompleteChanged(e.target, item)} />
                </div>
            </div>
        </ListGroup.Item>
    )
}