import React from 'react'

import { ListGroup } from 'react-bootstrap'

export default function TrackList({ children }) {
    return (
        <ListGroup variant="flush" className="overflow-auto">
            {children}
        </ListGroup>
    )
}