import React from 'react'

import { useDispatch } from 'react-redux'

import { setTitle } from 'Areas/Core/CoreSlice'

export default function NavTitle({ text }) {
    const dispatch = useDispatch()

    dispatch(setTitle(text))

    return <></>
}