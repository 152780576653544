import { createSlice } from '@reduxjs/toolkit'

export const musicSettingsSlice = createSlice({
    name: 'musicSettings',
    initialState: {
        volume: 100
    },
    reducers: {
        setVolume: (state, action) => {
            state.volume = action.payload
        }
    },
  })
  
  export const { setVolume } = musicSettingsSlice.actions
  
  export default musicSettingsSlice.reducer