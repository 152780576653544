import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { setArtist, setAlbum } from './LibrarySlice'
import { setQueue } from './MusicSlice'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'

import TrackList from './TrackList'
import TrackListItem from './TrackListItem'
import { ListGroup } from 'react-bootstrap'

import MusicPlayer from './Services/MusicPlayer'

export default function() {
    const dispatch = useDispatch()

    const playingSongId = useSelector(state => state.music.playingSongId)

    const selectedArtist = useSelector(state => state.musicLibrary.artist)
    const selectedAlbum = useSelector(state => state.musicLibrary.album)

    const [songs, setSongs] = useState([])
    const [artists, setArtists] = useState([])
    const [albums, setAlbums] = useState([])

    const onSelectArtist = (artist) => {
        dispatch(setArtist(artist))
        dispatch(setAlbum(null))
    }

    const onSelectAlbum = (album) => {
        dispatch(setAlbum(album))
    }

    const listSongs = async () => {
        const response = await http.get(`/music/songs/`, {
            params: {
                artist: selectedArtist,
                album: selectedAlbum
            }
        })

        setSongs(response.data)
    }

    const listArtists = async () => {
        const response = await http.get(`/music/artists/`)

        setArtists(response.data)
    }

    const listAlbums = async () => {
        const response = await http.get(`/music/albums`, {
            params: {
                artist: selectedArtist
            }
        })

        setAlbums(response.data)
    }

    const onPlay = (index) => {
        let queue = []

        for (let i = index; i < songs.length; i++) {
            queue.push(songs[i])
        }

        dispatch(setQueue(queue))

        MusicPlayer.playQueue()
    }

    useEffect(() => {
        listArtists()
    }, [])

    useEffect(() => {
        listAlbums()
    }, [selectedArtist])

    useEffect(() => {
        listSongs()
    }, [selectedAlbum])

    return (
        <div className="row">
            <NavTitle text="Library" />
            
            <div className="col-6">
                <span>Artist</span>
                <ListGroup variant="flush" style={{'height': '200px'}} className="bg-secondary overflow-auto">
                    {artists.map(x => (
                        <ListGroup.Item action onClick={() => onSelectArtist(x)} active={x === selectedArtist}>{x ? x : '(No Artist)'}</ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
            <div className="col-6">
                <span>Album</span>

                <ListGroup style={{'height': '200px'}} className="bg-secondary overflow-auto">
                    {albums.map(x => (
                        <ListGroup.Item action onClick={() => onSelectAlbum(x)} active={x === selectedAlbum}>{x ? x : '(No Album)'}</ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
            <div className="d-flex mb-3">
                <div className="flex-grow-1 me-2">
                    
                </div>
                <div className="flex-grow-1 ms-2">
                   
                </div>
            </div>
            <div className="col">
                <TrackList>
                    {songs.map((x, index) => (
                        <TrackListItem key={x.id} song={x} position={x.track} active={playingSongId === x.id} onPlay={() => onPlay(index)} />
                    ))}
                </TrackList>
            </div>
        </div>
    )
}