import React, { useEffect } from 'react'

import useMobileViewport from 'hooks/useMobileViewport'

import MobileHost from './Mobile/Host'
import DesktopHost from './Desktop/Host'

export default function Host() {
    const isMobileViewport = useMobileViewport()

    return isMobileViewport ? <MobileHost /> : <DesktopHost />
}