import React, { useEffect, useState } from 'react'

import { DateTime } from 'luxon'

import { Card, Breadcrumb, Button, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'
import DatePicker from 'components/DatePicker'

import TodoListItem from './TodoListItem'

export default function() {
    const [selectedDate, setSelectedDate] = useState(DateTime.now())

    const [items, setItems] = useState([])

    useEffect(() => {
        listMyDay(selectedDate)
    }, [])

    const listItems = async (rangeStart, rangeEnd) => {
        const response = await http.get(`todo/list`, {
            params: {
                rangeStart: rangeStart.toISO(),
                rangeEnd: rangeEnd.toISO(),
                includeCompleteItems: true
            }
        })

        setItems(response.data)
    }

    const listMyDay = async (date) => {
        await listItems(date.startOf('day'), date.endOf('day'))
    }

    const onDateChanged = (date) => {
        const luxonDate = DateTime.fromJSDate(date)

        setSelectedDate(luxonDate)

        listMyDay(luxonDate)
    }

    return (
        <div>
            <NavTitle text="Todo" />
            <nav className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb">
                    <Breadcrumb.Item active>Todo</Breadcrumb.Item>
                </ol>
                    
                <div>
                    <Button as={Link} variant="primary" to="/todo/create">
                        Create
                    </Button>
                </div>
            </nav>

            <DatePicker selected={selectedDate.toJSDate()} onChange={onDateChanged} showTimeSelect={false} dateFormat="MMMM d, yyyy" />

            {/* <div className="d-flex justify-content-between">
                <div className="d-flex flex-row flex-fill">
                    <DatePicker selected={rangeStart.toJSDate()} onChange={(date) => setRangeStart(DateTime.fromJSDate(date))} />
                    <DatePicker selected={rangeEnd.toJSDate()} onChange={(date) => setRangeEnd(DateTime.fromJSDate(date))} />
                </div>
                <div className="ms-2">
                    <Button variant="primary" onClick={() => listItems(rangeStart, rangeEnd)}>
                        Filter
                    </Button>
                </div>
            </div> */}

            <Card>
                <Card.Body>
                    <Card.Title>My Day</Card.Title>
                    <Card.Subtitle>{selectedDate.toFormat('cccc, LLLL d')}</Card.Subtitle>
                </Card.Body>
            </Card>
                
            <div>
                <ListGroup>
                    {items.map(x => (
                        <TodoListItem key={x.id} item={x} />
                    ))}
                </ListGroup>

            </div>
        </div>
    )
}