import React, { createContext, useRef } from 'react'
import ViewStack from './ViewStack';

export const viewStackContext = createContext({});

export default function ViewportProvider({ children }) {
    const viewStackRef = useRef()

    return (
        <viewStackContext.Provider value={{ ref: viewStackRef }}>
            <ViewStack ref={viewStackRef}>
                {children}
            </ViewStack>
        </viewStackContext.Provider>
    );
};