import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { ListGroup } from 'react-bootstrap'

import useContextMenu from 'hooks/useContextMenu'

import http from 'Services/Http'

export default function PlaylistItem({ children, playlist }) {
    const [isDeleted, setIsDeleted] = useState(false)

    const onAddToQueue = () => {
    }

    const onDelete = async () => {
        const response = await http.delete(`/music/playlists/${playlist.id}`)

        setIsDeleted(true)
    }

    const contextMenuComponent = () => {
        return (
            <ListGroup>
                <ListGroup.Item action onClick={onAddToQueue}>Add to queue</ListGroup.Item>
                <ListGroup.Item action onClick={onDelete} className="list-group-item-danger">Delete</ListGroup.Item>
            </ListGroup>
        )
    }

    const { bindContextMenu, contextMenu } = useContextMenu(contextMenuComponent())

    if (isDeleted) {
        return <></>
    }

    return (
        <ListGroup.Item action as={Link} to={'/music/playlist/' + playlist.id} onContextMenu={bindContextMenu}>
            {contextMenu}

            {children}
        </ListGroup.Item>
    )
}