import { createSlice } from '@reduxjs/toolkit'

export const coreSlice = createSlice({
  name: 'core',
  initialState: {
    title: null,
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
  },
})

export const { setTitle } = coreSlice.actions

export default coreSlice.reducer