import React, { useState } from 'react'

import { Button, Form, Card } from 'react-bootstrap'

import { useHistory } from 'react-router'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'
import auth from 'Services/Authorization'

export default function Login() {
    const history = useHistory()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const processLogin = async () => {
        await auth.performLogin(email, password)

        history.push(`/`)
    }

    return (
        <div className="container">
            <Card bg="secondary">
                <Card.Body>
                    <NavTitle text="Login" />

                    <Form>
                        <Form.Group className="my-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Button onClick={processLogin} variant="primary">
                            Login
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}