import React from 'react'

import { useSelector } from 'react-redux'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useViewStack from 'hooks/useViewStack'

import PlayButton from 'Areas/Music/PlayButton'

import MusicPlayer from 'Areas/Music/Services/MusicPlayer'
import { useHistory } from 'react-router'

import NowPlayingOptions from './NowPlayingOptions'

export default function NowPlaying() {
    const viewStack = useViewStack()
    const history = useHistory()

    const playingSongMetadata = useSelector(state => state.music.playingSongMetadata)

    const onHide = () => {
        viewStack.current.popView()
    }

    const onNext = () => {
        MusicPlayer.playNext()
    }

    const onDisplayOptions = () => {
        viewStack.current.pushView(<NowPlayingOptions />)
    }

    const onDisplayQueue = () => {
        history.push(`/music/queue`)

        viewStack.current.popView()
    }

    return (
        <div className="d-flex flex-column flex-fill m-2">
            <div className="d-flex justify-content-between">
                <Button variant="primary" className="transparent-button" onClick={onHide}>
                    <FontAwesomeIcon icon="chevron-down" />
                </Button>
                <Button variant="primary" className="transparent-button" onClick={onDisplayOptions}>
                    <FontAwesomeIcon icon="ellipsis-v" />
                </Button>
            </div>

            <div className="d-flex flex-fill"></div>

            <div className="d-flex flex-column mx-2">
                <h5>{playingSongMetadata.song.name}</h5>
                <h6 className="text-muted">{playingSongMetadata.song.artist}</h6>
            </div>

            <div className="d-flex justify-content-between">
                <Button variant="primary" className="transparent-button">
                    <FontAwesomeIcon icon="random" />
                </Button>

                <div className="d-flex">
                    <Button variant="primary" className="transparent-button">
                        <FontAwesomeIcon icon="step-backward" size="2x" />
                    </Button>
                    <PlayButton song={playingSongMetadata.song} size="3x" />
                    <Button variant="primary" className="transparent-button" onClick={onNext}>
                        <FontAwesomeIcon icon="step-forward" size="2x" />
                    </Button>
                </div>

                <Button variant="primary" className="transparent-button">
                    <FontAwesomeIcon icon="redo-alt" />
                </Button>
            </div>
            <div className="d-flex my-2 justify-content-between">
                <Button variant="primary" className="transparent-button">
                    <FontAwesomeIcon icon="mobile-alt" />
                </Button>

                <Button variant="primary" className="transparent-button" onClick={onDisplayQueue}>
                    <FontAwesomeIcon icon="list" />
                </Button>
            </div>
        </div>
    )
}