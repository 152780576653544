import http from './Http'
import unauthorizedHttp from './UnauthorizedHttp'

import { store } from 'app/store'
import { setToken } from 'Areas/Authorization/AuthorizationSlice'

export default {
    storeToken: function(token) {
        const previousTokens = this.retrieveToken()
        if (previousTokens != null && token.refresh_token == null) {
            token.refresh_token = previousTokens.refresh_token
        }

        store.dispatch(setToken(token))
    },
    retrieveToken: () => {
        const state = store.getState()

        return state.authorization.token
    },
    isExpired: function(token) {
        if (!token) {
            return true
        }

        const expirationDate = new Date()
        expirationDate.setTime(token.expiration_date)

        if (Date.now() > expirationDate) {
            return true
        }

        return false
    },
    isAuthenticated: function(token) {
        // if (this.isExpired(token)) {
        //     return false
        // }

        return token !== null
    },
    performLogin: async function (email, password) {
        const data = {
            client_id: 'web',
            grant_type: 'password',
            scope: 'openid offline_access',
            username: email,
            password: password
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const params = new URLSearchParams()
        for (let key in data) {
            params.append(key, data[key]);
        }

        const response = await http.post(`connect/token`, params, config)
        const token = response.data

        const now = new Date()

        token.expiration_date = new Date(now.getTime() + token.expires_in * 1000).getTime().toString()

        this.storeToken(token)

        return token
    },
    refreshToken: async function (token) {
        const data = {
            client_id: 'web',
            grant_type: 'refresh_token',
            refresh_token: token.refresh_token
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const params = new URLSearchParams()
        for (let key in data) {
            params.append(key, data[key]);
        }

        const response = await unauthorizedHttp.post(`connect/token`, params, config)
        const responseToken = response.data

        const now = new Date()
        responseToken.expiration_date = new Date(now.getTime() + responseToken.expires_in * 1000).getTime().toString()

        this.storeToken(responseToken)

        return responseToken
    },
}