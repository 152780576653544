import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'

import { setQueue } from 'Areas/Music/MusicSlice'

import EditPlaylistModal from 'Areas/Music/EditPlaylistModal'
import TrackList from 'Areas/Music/TrackList'
import PlaylistTrackListItem from 'Areas/Music/PlaylistTrackListItem'

import MusicPlayer from '../Services/MusicPlayer'

export default function ViewPlaylist() {
    const dispatch = useDispatch()

    const [playlist, setPlaylist] = useState()
    const [isEditing, setIsEditing] = useState()

    const { id } = useParams();

    const loadPlaylist = async () => {
        const response = await http.get(`/music/playlists/${id}`)

        setPlaylist(response.data)
    }

    useEffect(async () => {
        loadPlaylist()
    }, [id])

    const onPlay = (index) => {
        let queue = []

        for (let i = index; i < playlist.items.length; i++) {
            const item = playlist.items[i]

            queue.push(item.song)
        }

        dispatch(setQueue(queue))

        MusicPlayer.playQueue()
    }

    if (!playlist) {
        return (
            <></>
        )
    }

    return (
        <div>
            <EditPlaylistModal playlist={playlist} show={isEditing} onHide={() => setIsEditing(false)} />

            <div className="d-flex d-row align-items-center justify-content-between">
                <NavTitle text={playlist.name} />
                <Button variant="primary" className="my-2" onClick={() => setIsEditing(true)}>
                    <FontAwesomeIcon icon="pencil-alt" />
                </Button>
                <div>
                    {playlist.items.length} songs
                </div>
            </div>

            <TrackList>
                {playlist.items.map((x, index) => (
                    <PlaylistTrackListItem key={x.id} playlist={playlist} playlistItem={x} position={index + 1} onPlay={() => onPlay(index)} />
                ))}
            </TrackList>
        </div>
    )
}