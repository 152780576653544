import React from 'react'

import NavTitle from 'components/NavTitle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Passport() {
    return (
        <div className="d-flex flex-fill">
            <NavTitle text="Health Passport" />
            <div className="d-flex align-items-center flex-fill overflow-auto">
                <div className="d-flex flex-fill justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <h3 className="my-2">Loading...</h3>
                        <FontAwesomeIcon icon="spinner" size="2x" spin />
                    </div>
                </div>
            </div>
        </div>
    )
}