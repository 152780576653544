import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setPlaylists } from 'Areas/Music/MusicSlice'

import NavTitle from 'components/NavTitle'

import { ListGroup } from 'react-bootstrap'

import PlaylistItem from 'Areas/Music/PlaylistItem'

import http from 'Services/Http'

export default function Playlists() {
    const playlists = useSelector(state => state.music.playlists)

    const dispatch = useDispatch()

    useEffect(async () => {
        const response = await http.get(`/music/playlists`)

        dispatch(setPlaylists(response.data))
    }, [])

    return (
        <div>
            <NavTitle text="Playlists" />
            <ListGroup>
                {playlists.map(x => (
                    <PlaylistItem playlist={x} key={x.id}>{x.name}</PlaylistItem>
                ))}
            </ListGroup>
        </div>
    )
}