import React from 'react'

import NavTitle from 'components/NavTitle'

export default function Library() {
    return (
        <div>
            <NavTitle text="Library" />
        </div>
    )
}