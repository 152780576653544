import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { ListGroup } from 'react-bootstrap'

import TrackListItem from './TrackListItem'

import http from 'Services/Http'

export default function PlaylistTrackListItem({ position, playlist, playlistItem, onPlay }) {
    const playingSongId = useSelector(state => state.music.playingSongId)
    const [isDeleted, setIsDeleted] = useState(false)

    const onRemoveFromPlaylist = async () => {
        const response = await http.delete(`/music/playlists/${playlist.id}/songs`, {
            data: {
                id: playlistItem.id
            }
        })

        setIsDeleted(true)
    }

    const contextMenuItems = () => {
        return (
            <>
                <ListGroup.Item action onClick={onRemoveFromPlaylist}>Remove from playlist</ListGroup.Item>
            </>
        )
    }

    if (isDeleted) {
        return (
            <></>
        )
    }

    return (
        <TrackListItem song={playlistItem.song} position={position} active={playingSongId === playlistItem.song.id} contextMenuItems={contextMenuItems()} onPlay={onPlay} />
    )
}