import React from 'react'

import { Route } from "react-router-dom";

import Home from './Home'

export default function Host() {
    return (
        <div className="d-flex flex-fill container">
            <Route path="/h1" component={Home} exact />
        </div>
    )
}