import React from 'react'

import { Route } from "react-router-dom";

import Home from './Home'
import Passport from './Passport'
import PrankPassport from './PrankPassport';
import Green from './Green'

export default function Host() {
    return (
        <div className="d-flex flex-fill container">
            <Route path="/flashpoint/passport" component={Passport} />
            <Route path="/flashpoint/prankport" component={PrankPassport} />
            <Route path="/flashpoint/green" component={Green} />
            <Route path="/flashpoint" component={Home} exact />
        </div>
    )
}