import React from 'react'

import { useSelector } from 'react-redux'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import musicPlayer from 'Areas/Music/Services/MusicPlayer'

export default function PlayButton({ song, size }) {
    const playingSongId = useSelector(state => state.music.playingSongId)
    const songState = useSelector(state => state.music.songState)

    const isPlaying = () => {
        return playingSongId === song.id && songState == 'playing'
    }

    const isPaused = () => {
        return playingSongId === song.id && songState == 'paused'
    }

    const onPlayPause = async (e) => {
        e.stopPropagation()

        if (isPlaying()) {
            musicPlayer.pause()
        } else {
            musicPlayer.play()
        }
    }

    return (
        <Button variant="success" className="me-2 transparent-button" onClick={onPlayPause}>
            {!isPlaying() && !isPaused() && (<FontAwesomeIcon icon="play" size={size} />)}
            {isPlaying() && (<FontAwesomeIcon icon="pause" size={size} />)}
            {isPaused() && (<FontAwesomeIcon icon="play" className="text-success" size={size} />)}
        </Button>
    )
}