import React from 'react'

import { Form } from 'react-bootstrap'

import { useSelector, useDispatch } from 'react-redux'

import { setVolume } from 'Areas/Music/MusicSettingsSlice'

import MusicPlayer from './Services/MusicPlayer'
import QueueButton from './QueueButton'

export default function VolumeControl() {
    const dispatch = useDispatch()

    const volume = useSelector(state => state.musicSettings.volume)

    const changeVolume = (newVolume) => {
        dispatch(setVolume(newVolume))

        MusicPlayer.changeVolume(newVolume)
    }

    return (
        <div className="d-flex flex-row align-items-center">
            <QueueButton />
            <Form.Range className="ms-2" defaultValue={volume} min={0} max={100} onChange={(e) => changeVolume(e.target.value)} />
        </div>
    )
}