import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'

import { useSelector } from 'react-redux'

import MusicMetadataService from './Services/MusicMetadataService'

export default function NowPlaying() {
    const playingSongId = useSelector(state => state.music.playingSongId)
    const [songMetadata, setSongMetadata] = useState()

    useEffect(async () => {
        if (!playingSongId) {
            setSongMetadata(null)
            return
        }

        const songMetadata = await MusicMetadataService.getSongMetadata(playingSongId)

        setSongMetadata(songMetadata)
    }, [playingSongId])

    if (!songMetadata) {
        return (
            <></>
        )
    }

    return (
        <div>
            <Card.Title>Now Playing</Card.Title>
            <hr />
            <Card.Subtitle>{songMetadata.song.name}</Card.Subtitle>
            <Card.Subtitle>{songMetadata.song.album}</Card.Subtitle>
            <Card.Subtitle>{songMetadata.song.artist}</Card.Subtitle>
        </div>
    )
}