import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { useSelector } from 'react-redux'

import auth from 'Services/Authorization'

export default function({ component: Component, ...rest }) {
    const token = useSelector((state) => state.authorization.token)
    const isAuthenticated = auth.isAuthenticated(token)

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
  }