import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { Link } from 'react-router-dom'

import { ListGroup } from 'react-bootstrap'

import Subtitle from 'components/Subtitle'

import { logout } from 'Areas/Authorization/AuthorizationSlice'

export default function AuthenticatedNavSidebar() {
    const dispatch = useDispatch()
    const history = useHistory()

    const onLogOut = () => {
        dispatch(logout())

        history.push(`/login`)
    }

    return (
        <div>
            <Subtitle>Welcome!</Subtitle>
            <ListGroup variant="flush">
                <ListGroup.Item action as={Link} to="/todo">Todo</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/music">Music</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/h1">H1</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/flashpoint">Flashpoint</ListGroup.Item>
                <ListGroup.Item action onClick={onLogOut}>Log Out</ListGroup.Item>
            </ListGroup>
        </div>
    )
}