import { createSlice } from '@reduxjs/toolkit'

export const librarySlice = createSlice({
    name: 'musicLibrary',
    initialState: {
        artist: null,
        album: null
    },
    reducers: {
        setArtist: (state, action) => {
            state.artist = action.payload
        },
        setAlbum: (state, action) => {
            state.album = action.payload
        }
    },
  })
  
  export const { setArtist, setAlbum } = librarySlice.actions
  
  export default librarySlice.reducer