import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'

import { setAlbum } from 'Areas/Music/LibrarySlice'

import { ListGroup } from 'react-bootstrap'

import http from 'Services/Http'

import './ViewArtist.css'

import NavTitle from 'components/NavTitle'

export default function ViewArtist() {
    const dispatch = useDispatch()
    const history = useHistory()

    const { id } = useParams()
    const [albums, setAlbums] = useState([])

    const listAlbums = async () => {
        const response = await http.get(`/music/albums`, {
            params: {
                artist: id
            }
        })

        setAlbums(response.data)
    }

    const onSelectAlbum = (album) => {
        dispatch(setAlbum(album))

        history.push(`/music/artists/${id}/${album}`)
    }

    useEffect(() => {
        listAlbums()
    }, [id])

    return (
        <div className="d-flex flex-column flex-fill overflow-auto">
            <NavTitle text={id} />
            <ListGroup className="album-box flex-grow-1">
                {albums.map(x => (
                    <ListGroup.Item key={x} action onClick={() => onSelectAlbum(x)}>{x}</ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}