import React, { useState } from 'react'

import { Form, Button } from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'

export default function Upload() {
    const { register, handleSubmit } = useForm();
    const [isUploading, setIsUploading] = useState(false)
    const [uploadPercentage, setUploadPercentage] = useState(0)

    const history = useHistory()

    const onSubmit = async (data) => {
        setIsUploading(true)

        setUploadPercentage(0)
        for (let i = 0; i < data.files.length; i++) {
            await uploadFile(data.files[i])

            setUploadPercentage(Math.round((i + 1) / (data.files.length + 1) * 100))
        }

        setIsUploading(false)

        history.push(`/music`)
    }

    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append('files', file);

        await http.post(`/music/songs/upload`, formData, {
            headers: {
                "Content-type": "multipart/form-data",
            },                    
        })
    }

    return (
        <div>
            <NavTitle text="Upload" />

            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>File</Form.Label>
                    <Form.Control {...register("files")} type="file" multiple />
                </Form.Group>

                {isUploading && (
                    <Form.Group className="mb-3">
                        <Form.Label>Uploading [{uploadPercentage}%]</Form.Label>
                    </Form.Group>
                )}

                <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={isUploading}>
                    Upload
                </Button>
            </Form>
        </div>
    )
}