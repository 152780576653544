import React, { useState } from 'react'

import { Button, Form, Card } from 'react-bootstrap'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'

export default function Register() {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirm, setPasswordConfirm] = useState()

    const processRegister = async () => {
        if (password !== passwordConfirm) {
            return
        }

        await http.post(`account/register`, {
            email: email,
            password: password
        })
    }

    return (
        <div className="container">
            <Card bg="secondary">
                <Card.Body>
                    <NavTitle text="Register" />
                    <Form>
                        <Form.Group className="my-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPasswordConfirm(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Button onClick={processRegister} variant="primary">
                            Register
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}