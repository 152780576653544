import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useSelector, useDispatch } from 'react-redux'

import NavTitle from 'components/NavTitle'

import Subtitle from 'components/Subtitle'

import http from 'Services/Http'

import { setQueue } from 'Areas/Music/MusicSlice'

import TrackList from 'Areas/Music/TrackList'
import TrackListItem from 'Areas/Music/TrackListItem'

import MusicPlayer from 'Areas/Music/Services/MusicPlayer'

export default function ViewAlbum() {
    const dispatch = useDispatch()

    const { artist, album } = useParams()

    const playingSongId = useSelector(state => state.music.playingSongId)

    const [songs, setSongs] = useState([])

    const listSongs = async () => {
        const response = await http.get(`/music/songs/`, {
            params: {
                artist: artist,
                album: album
            }
        })

        setSongs(response.data)
    }

    const onPlay = (index) => {
        let queue = []

        for (let i = index; i < songs.length; i++) {
            queue.push(songs[i])
        }

        dispatch(setQueue(queue))

        MusicPlayer.playQueue()
    }

    useEffect(() => {
        listSongs()
    }, [])

    return (
        <div className="d-flex flex-column">
            <NavTitle text={album} />
            <Subtitle className="text-muted">{artist}</Subtitle>
            <TrackList>
                {songs.map((x, index) => (
                    <TrackListItem key={x.id} song={x} position={x.track} active={playingSongId === x.id} onPlay={() => onPlay(index)} />
                ))}
            </TrackList>
            <div className="my-2 text-muted">
                <span>{songs.length} songs</span>
            </div>
        </div>
    )
}