import React from 'react'

import NavTitle from 'components/NavTitle'

export default function Stage3() {
    return (
        <div className="d-flex flex-fill">
            <NavTitle text="Kennkarte" />
            <div className="d-flex align-items-center flex-fill overflow-auto">
                <div className="d-flex flex-fill justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <iframe height="400" src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&controls=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}