import React from 'react'

import NavTitle from 'components/NavTitle'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Stage2({ continuation }) {
    const onClick = () => {
        continuation()
    }

    return (
        <div className="d-flex flex-fill">
            <NavTitle text="Health Passport" />
            <div className="d-flex align-items-center flex-fill overflow-auto">
                <div className="d-flex flex-fill justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon="exclamation-triangle" size="4x" className="text-danger" />
                        <h3 className="my-2">Something went wrong</h3>
                        <h6 className="text-muted">Unable to connect to server</h6>
                        <Button onClick={onClick} className="my-2" variant="primary" size="lg">
                            Try again
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}