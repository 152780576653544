import React from 'react'

import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useLocation, useHistory, Link } from 'react-router-dom'

export default function QueueButton() {
    const location = useLocation()
    const history = useHistory()

    const isOnQueue = location.pathname === '/music/queue'

    if (isOnQueue) {
        return (
            <Button onClick={history.goBack} variant="outline-primary" className="border-0" active>
                <FontAwesomeIcon icon="list-ul" />
            </Button>
        )
    }

    return (
        <Button as={Link} to="/music/queue" variant="outline-primary" className="border-0">
            <FontAwesomeIcon icon="list-ul" />
        </Button>
    )
}