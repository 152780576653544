import { createSlice } from '@reduxjs/toolkit'

export const musicSlice = createSlice({
  name: 'music',
  initialState: {
    playingSongId: null,
    playingSongMetadata: null,
    songState: '',
    queue: {
      items: []
    },
    downloads: {
      items: []
    },
    songCache: {},
    playlists: []
  },
  reducers: {
    setPlayingSong: (state, action) => {
        state.playingSongId = action.payload.songId
        state.playingSongMetadata = action.payload.metadata
    },
    setSongState: (state, action) => {
        state.songState = action.payload.state
    },
    addToQueue: (state, action) => {
        state.queue.items.push(action.payload)
    },
    shiftQueue: (state) => {
        state.queue.items.shift()
    },
    setQueue: (state, action) => {
        state.queue.items = action.payload
    },
    addToDownloadQueue: (state, action) => {
        state.downloads.items.push(action.payload)
    },
    shiftDownloadQueue: (state) => {
        state.downloads.items.shift()
    },
    addSongToCache: (state, action) => {
        state.songCache[action.payload.id] = action.payload
    },
    setPlaylists: (state, action) => {
        state.playlists = action.payload
    }
  },
})

export const { setPlayingSong, setSongState, addToQueue, shiftQueue, addSongToCache, setQueue, setPlaylists, addToDownloadQueue, shiftDownloadQueue } = musicSlice.actions

export default musicSlice.reducer