import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setPlaylists } from 'Areas/Music/MusicSlice'

import { Route, Switch } from 'react-router-dom'

import http from 'Services/Http'

import Sidebar from 'Areas/Music/Sidebar'

import Library from 'Areas/Music/Library'
import Discover from 'Areas/Music/Discover'
import ViewPlaylist from 'Areas/Music/ViewPlaylist'
import Upload from 'Areas/Music/Upload'
import Queue from 'Areas/Music/Queue'
import DownloadQueue from 'Areas/Music/DownloadQueue'

import PlayerControls from 'Areas/Music/PlayerControls'

export default function() {
    const dispatch = useDispatch()

    useEffect(async () => {
        const response = await http.get(`/music/playlists`)

        dispatch(setPlaylists(response.data))
    }, [])

    return (
        <div className="d-flex flex-row flex-grow-1 overflow-hidden">
            <Sidebar />
            
            <div className="d-flex flex-column flex-fill overflow-hidden">
                <div className="flex-grow-1 p-3 overflow-auto">
                    <div className="d-flex flex-column">
                        <Switch>
                            <Route path={`/music/playlist/:id`} component={ViewPlaylist} />
                            <Route path={`/music/discover`} component={Discover} />
                            <Route path={`/music/downloads`} component={DownloadQueue} />
                            <Route path={`/music/upload`} component={Upload} />
                            <Route path={`/music/queue`} component={Queue} />
                            <Route path={`/music`} component={Library} />
                        </Switch>
                    </div>
                </div>

                <div>
                    <div className="bottom-0 bg-dark align-self-stretch d-flex justify-content-center" style={{height: '100px'}}>
                        <PlayerControls />
                    </div>  
                </div>
            </div>
        </div>
    )
}