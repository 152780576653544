import { store } from 'app/store'

import { setPlayingSong, setSongState, setQueuePosition, shiftQueue } from '../MusicSlice'

import { Howl, Howler } from 'howler'

import auth from 'Services/Authorization'

import { API_HOST } from 'env'

import http from 'Services/Http'
import MusicMetadataService from './MusicMetadataService'

export default {
    currentSong: null,
    changeVolume: function(volume) {
        Howler.volume(volume / 100.0)
    },
    createSong: async function(id) {
        this.stopAudio()

        const songMetadata = await MusicMetadataService.getSongMetadata(id)

        navigator.mediaSession.metadata = new window.MediaMetadata({
            title: songMetadata.song.name,
            artist: songMetadata.song.artist,
            album: songMetadata.song.album,
        });
        navigator.mediaSession.setActionHandler('play', () => this.play());
        navigator.mediaSession.setActionHandler('pause', () => this.pause());
        navigator.mediaSession.setActionHandler('stop', () => this.stop());
        navigator.mediaSession.setActionHandler('nexttrack', () => this.playNext())

        const songStreamResponse = await http.get(`/music/songs/${id}/stream`)

        const songUrl = songStreamResponse.data.url

        const howl = new Howl({
            src: songUrl,
            html5: true,
            format: ['webm', 'mp3']
        });

        howl.once('end', async () => {
            await this.playNext()
        })

        this.currentSong = {
            howl: howl,
            id: id,
            metadata: songMetadata
        }

        const state = store.getState()
        Howler.volume(state.musicSettings.volume / 100.0)
    },
    play: function() {
        this.currentSong.howl.play()

        store.dispatch(setPlayingSong({ songId: this.currentSong.id, metadata: this.currentSong.metadata }))
        store.dispatch(setSongState({ state: 'playing' }))
    },
    playQueue: async function() {
        const state = store.getState()

        const queue = state.music.queue.items
        if (queue.length > 0) {
            const nextItem = queue[0]

            await this.createSong(nextItem.id)
            this.play()
        }
    },
    pause: function() {
        this.currentSong.howl.pause()
        store.dispatch(setSongState({ state: 'paused' }))
    },
    stopAudio: function() {
        if (!this.currentSong) {
            return
        }

        this.currentSong.howl.stop()
        this.currentSong = null
    },
    stop: function() {
        this.stopAudio()

        store.dispatch(setPlayingSong({ songId: null }))
        store.dispatch(setSongState({ state: '' }))
    },
    playNext: async function() {
        const state = store.getState()

        store.dispatch(shiftQueue())

        if (state.music.queue.items.length > 1) {
            const nextItem = state.music.queue.items[1]

            await this.createSong(nextItem.id)
            this.play()
        } else {
            this.stop()
        }
    },
}