import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navbar from 'Navbar'
import ViewStack from 'components/ViewStack'

import AuthRoute from 'components/AuthRoute'

import Home from 'Home'

import Login from 'Areas/Authorization/Login'
import Register from 'Areas/Authorization/Register'

import TodoHost from 'Areas/Todo/Host'
import MusicHost from 'Areas/Music/Host'
import FlashpointHost from 'Areas/Flashpoint/Host'
import H1Host from 'Areas/H1/Host'

export default function App() {
  return (
    <div className="h-100 d-flex flex-column">
      <div className="mb-2 flex-shrink-1">
        <Navbar />
      </div>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />

        <AuthRoute path="/todo" component={TodoHost} />
        <AuthRoute path="/music" component={MusicHost} />
        <AuthRoute path="/flashpoint" component={FlashpointHost} />
        <AuthRoute path="/h1" component={H1Host} />

        <Route path="/" component={Home} />
      </Switch>
    </div>
  );
}