import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export default forwardRef((props, ref) => {
    const [viewStack, setViewStack] = useState([])
    const history = useHistory()

    const popView = () => {
        const updatedViewStack = viewStack.slice()

        updatedViewStack.pop()

        setViewStack(updatedViewStack)
    }

    useImperativeHandle(ref, () => ({
        pushView: (view) => {
            const updatedViewStack = viewStack.slice()

            updatedViewStack.push(view)

            setViewStack(updatedViewStack)
        },
        popView: () => {
            popView()
        }
    }));

    history.block((location, action) => {
        if (action === 'POP') {
            if (viewStack.length > 0) {
                popView()

                return false
            }
        }

        return true
    })

    if (viewStack.length === 0) {
        return props.children
    }

    return (
        <div className="d-flex position-absolute top-0 start-0 end-0 bottom-0" style={{ zIndex: '10000000' }}>
            {viewStack.at(-1)}
        </div>
    )
})