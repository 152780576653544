import React from 'react'

import axios from 'axios'

import auth from 'Services/Authorization'

import { API_HOST } from 'env'

const instance = axios.create({
    baseURL: API_HOST
})

instance.interceptors.request.use(function (config) {
    const token = auth.retrieveToken()

    let originalRequest = config;
    if (token) {
        if (auth.isExpired(token)) {
            return auth.refreshToken(token).then((refreshedToken) => {
                originalRequest.headers['Authorization'] = `Bearer ${refreshedToken.access_token}`;
                return Promise.resolve(originalRequest);
            });
        } else {
            config.headers['Authorization'] = `Bearer ${token.access_token}`
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default instance