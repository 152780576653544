import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { ListGroup } from 'react-bootstrap'

import { setArtist } from 'Areas/Music/LibrarySlice'

import NavTitle from 'components/NavTitle'

import http from 'Services/Http'
import { useHistory } from 'react-router'

export default function Artists() {
    const dispatch = useDispatch()
    const history = useHistory()

    const selectedArtist = useSelector(state => state.musicLibrary.artist)

    const [artists, setArtists] = useState([])

    const listArtists = async () => {
        const response = await http.get(`/music/artists/`)

        setArtists(response.data)
    }

    const selectArtist = (artist) => {
        dispatch(setArtist(artist))

        history.push(`/music/artists/${artist}`)
    }

    useEffect(() => listArtists(), [])

    return (
        <div>
            <NavTitle text="Artists" />
            <ListGroup>
                {artists.map(x => (
                    <ListGroup.Item action key={x} onClick={() => selectArtist(x)}>{x}</ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}