import logo from './logo.svg';

import 'react-datepicker/dist/react-datepicker.css'

// import 'bootstrap/dist/css/bootstrap.min.css'
import "bootswatch/dist/darkly/bootstrap.min.css"

import "react-contexify/dist/ReactContexify.css";

import './App.css';

import Host from 'Host'

import ViewportProvider from 'components/ViewportProvider'
import ViewStackProvider from 'components/ViewStackProvider'

import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <ViewStackProvider>
        <ViewportProvider>
          <div className="position-absolute h-100 container-fluid px-0">
            <Host />
          </div>
        </ViewportProvider>
      </ViewStackProvider>
    </Router>
  );
}

export default App;
