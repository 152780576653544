import React from 'react'

import { useSelector } from 'react-redux'

import { Button, Form } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MusicPlayer from './Services/MusicPlayer'
import VolumeControl from './VolumeControl'

export default function PlayerControls() {
    const playingSongId = useSelector(state => state.music.playingSongId)
    const songState = useSelector(state => state.music.songState)

    const canControlMusic = () => {
        return !!playingSongId
    }

    const canPlay = () => {
        return !songState || songState === 'paused'
    }

    const canPause = () => {
        return songState == 'playing'
    }

    const onPlay = () => {
        MusicPlayer.play()
    }

    const onPause = () => {
        MusicPlayer.pause()
    }

    const onStop = () => {
        MusicPlayer.stop()
    }

    const onNext = async () => {
        await MusicPlayer.playNext()
    }

    if (canControlMusic()) {
        return (
            <div className="d-flex flex-grow-1">
                <div className="d-flex flex-grow-1 align-items-center justify-content-center">
                    {canPlay() && (<Button variant="primary" onClick={onPlay}>
                        <FontAwesomeIcon icon="play" />
                    </Button>)}
                    {canPause() && (<Button variant="primary" onClick={onPause}>
                        <FontAwesomeIcon icon="pause" />
                    </Button>)}
                    <Button variant="primary" onClick={onStop}>
                        <FontAwesomeIcon icon="stop" />
                    </Button>
                    <Button variant="primary" onClick={onNext}>
                        <FontAwesomeIcon icon="forward" />
                    </Button>
                </div>
                <div className="d-flex justify-content-end align-items-center pe-4">
                    <VolumeControl />
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex-grow-1 justify-content-end">
            <div className="d-flex justify-content-end align-items-center pe-4">
                <VolumeControl />
            </div>
        </div>
    )
}