import React, { useState } from 'react'
import { Breadcrumb, Card, Form, Button } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'

import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'components/DatePicker'

import http from 'Services/Http'

import NavTitle from 'components/NavTitle'

export default function() {
    const { register, handleSubmit, control } = useForm()
    const history = useHistory()

    const onSubmit = async (data) => {
         await http.post(`todo`, {
            title: data.title,
            dateTime: data.dateTime
        })

        history.push(`/todo`)
    }

    return (
        <div>
            <NavTitle text="Create Todo" />
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{to: "/todo"}}>Todo</Breadcrumb.Item>
                <Breadcrumb.Item active>Create</Breadcrumb.Item>
            </Breadcrumb>
        
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Controller
                        control={control}
                        name='dateTime'
                        defaultValue={new Date()}
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" {...register("title")} />
                </Form.Group>

                <Button type="submit" variant="primary">
                    Create
                </Button>
            </Form>
        </div>
    )
}