import { createSlice } from '@reduxjs/toolkit'

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: {
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    logout: (state) => {
      state.token = null
    }
  },
})

export const { setToken, logout } = authorizationSlice.actions

export default authorizationSlice.reducer