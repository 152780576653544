import React from 'react'

import { Link } from 'react-router-dom'

import { ListGroup } from 'react-bootstrap'

import Subtitle from 'components/Subtitle'

export default function AnonymousNavSidebar() {
    return (
        <div>
            <Subtitle>Login to continue.</Subtitle>
            <ListGroup variant="flush">
                <ListGroup.Item action as={Link} to="/login">Login</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/register">Register</ListGroup.Item>
            </ListGroup>
        </div>
    )
}