import React from 'react'

import { useSelector } from 'react-redux'

import { Button, ListGroup } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import musicPlayer from './Services/MusicPlayer'

import useContextMenu from 'hooks/useContextMenu'

import TrackContextMenu from './TrackContextMenu'

import useMobileViewport from 'hooks/useMobileViewport'

import { markFavorite } from './Services/MusicFavoritesService'

export default function TrackListItem({ song, position, active, contextMenuItems, onPlay }) {
    const isMobileViewport = useMobileViewport()

    const playingSongId = useSelector(state => state.music.playingSongId)
    const songState = useSelector(state => state.music.songState)

    const hasPosition = () => {
        return !!position
    }

    const isPlaying = () => {
        return playingSongId === song.id && songState == 'playing'
    }

    const isPaused = () => {
        return playingSongId === song.id && songState == 'paused'
    }

    const onPlayPause = async (e) => {
        e.stopPropagation()

        if (isPlaying()) {
            musicPlayer.pause()
            return
        }

        if (!isPaused()) {
            if (onPlay) {
                onPlay()
            } else {
                await musicPlayer.createSong(song.id)
                musicPlayer.play()
            }
        } else {
            musicPlayer.play()
        }
    }

    const onToggleFavorite = (e) => {
        e.stopPropagation()

        markFavorite(song.id, true)
    }

    const onExpandOptions = (e, bindContextMenu) => {
        e.stopPropagation()

        bindContextMenu(e)
    }

    const desktopTrackListControls = () => {
        if (isMobileViewport) {
            return
        }

        return (
            <div>
                {hasPosition() && (<span className="align-self-center pe-3">
                    {position}
                </span>)}
                <Button variant="primary" className="me-2 transparent-button" onClick={onPlayPause}>
                    {!isPlaying() && !isPaused() && (<FontAwesomeIcon icon="play" />)}
                    {isPlaying() && (<FontAwesomeIcon icon="pause" />)}
                    {isPaused() && (<FontAwesomeIcon icon="play" className="text-success" />)}
                </Button>
            </div>
        )
    }

    const { bindContextMenu, contextMenu } = useContextMenu(<TrackContextMenu song={song} additionalItems={contextMenuItems} />)

    return (
        <ListGroup.Item action onContextMenu={bindContextMenu} onClick={onPlayPause} active={active}>
            {contextMenu}

            <div className="d-flex flex-row">
                {desktopTrackListControls()}
                <div className="d-flex flex-grow-1">
                    <div className="row flex-grow-1">
                        <div className="col-sm-12 col-md-6 align-self-center">
                            <div className="d-flex flex-column">
                                <span>{song.name}</span>
                                <span className="text-muted">{song.artist}</span>
                            </div>
                        </div>
                        {!isMobileViewport && (
                            <div className="col-6 align-self-center">
                                <span>{song.album}</span>
                            </div>
                        )}
                    </div>
                  
                </div>
               
                <div>
                    <Button variant="outline-success" className="transparent-button" onClick={onToggleFavorite}>
                        <FontAwesomeIcon icon={['far', 'heart']} />
                    </Button>
                    <Button variant="primary" className="transparent-button" onClick={(e) => onExpandOptions(e, bindContextMenu)}>
                        <FontAwesomeIcon icon="ellipsis-v" />
                    </Button>
                </div>
            </div>
        </ListGroup.Item>
    )
}